import { apiGet, apiPost } from './service'

export async function listDrivers(data) {
	return await apiPost("/api/Drivers/list", data);
}

export async function fullListDrivers(data) {
	return await apiPost("/api/Drivers/fulllist", data);
}

export function getDrivers(data) {
	let url = "/api/Drivers/read/" + data;
	return apiGet(url, {params: {timestamp: Math.random()}});
}

export function postDrivers(data) {
	return apiPost("/api/Drivers/edit", data);
}

export function addDrivers(data) {
	return apiPost("/api/Drivers/add", data);
}

export function deleteDrivers(data) {
	return apiGet("/api/Drivers/delete/" + data);
}
